@charset "utf-8";
/* CSS Document */

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 330px) {
}


.container{ width:100%!important; padding:0!important;}
.row{ margin:0!important;}
.col-md-9{ width:100%!important; padding:0;}


#top{
	width:100%;
}
@media screen and (max-width: 414px) {
}
#top img{
	width:100%;
}



@media screen and (max-width: 414px){


}


/* ++++++++++++++++++++ */
/* +++ top_00 +++++++++ */
/* ++++++++++++++++++++ */


#top_00 h2{
	font-family:Arial, Helvetica, sans-serif;
	font-size:34px;
	text-align:center;
	font-weight:normal;
}

#top_00 hr.line_1{
	border:none;
	background:#333;
	width:1px;
	height:50px;
	display:block;
	margin:20px auto 40px auto;
}



/* ++++++++++++++++++++ */
/* +++ top_01 +++++++++ */
/* ++++++++++++++++++++ */

.top_01{ margin:50px 0;}
.top_01 ul{ width:100%;}
.top_01 li{ width:33%; min-height:390px; border-right:#333 1px solid;}
.top_01 li:nth-child(3){ border-right:none;}

.top_01 li img{ width:91%; display:block; margin:0 auto;}
.top_01 li p{ width:80%; margin:0 auto; padding:0; line-height:24px;}
.top_01 li p.p_name{ padding:0 0 20px 0;}
.top_01 li p.price{ text-align:center;}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
.top_01 li{ width:33%; min-height:280px;}
}
@media screen and (max-width: 450px) {
.top_01 li{ width:100%; min-height:inherit; border-right:none; border-bottom:#ccc 1px solid; padding:0 0 30px 0; margin:0 0 30px 0; }
.top_01 li:nth-child(3){ border:none;}
.top_01 li p.p_name{ text-align:center;}

}
@media screen and (max-width: 330px) {
}




/* ++++++++++++++++++++ */
/* +++ top_02 +++++++++ */
/* ++++++++++++++++++++ */

.top_02{ padding:50px 0; background:#ececec;}
.top_02 ul{ width:100%;}
.top_02 li{ width:33%; text-align:center; padding:0 0 30px 0;}
.top_02 a{ color:#333; text-decoration:none; display:block; padding:10px 0;}
.top_02 li:nth-child(3){ border-right:none;}

.top_02 img.img_01{ width:60%; display:block; margin:0 auto;}

/*タブ切り替え全体のスタイル*/
.tabs_01{
  margin-top: 50px;
  padding-bottom: 40px;
  box-shadow:none;
  width: 800px;
  margin: 0 auto;
}

/*タブのスタイル*/
.tab_item {
  width: calc(100%/3);
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: center;
  color: #333;
  font-weight:normal;
  display: block;
  float: left;
  text-align: center;
  transition: all 0.2s ease;
}
.tab_item:hover {
  opacity: 0.75;
}

/*ラジオボタンを全て消す*/
input[name="tab_item"] {
  display: none;
}

/*タブ切り替えの中身のスタイル*/
.tab_content {
  display: none;
  padding: 40px 0 0 0;
  clear: both;
  overflow: hidden;
}


/*選択されているタブのコンテンツのみを表示*/
#all:checked ~ #all_content,
#programming:checked ~ #programming_content,
#design:checked ~ #design_content {
  display: block;
}

/*選択されているタブのスタイルを変える*/
.tabs_01 input:checked + .tab_item {
  color: #333;
  border-bottom:#333 2px solid;
}



@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
.tabs_01{ width:100%;}
.tab_content img{ width:100%;}
}
@media screen and (max-width: 450px) {
.top_02 ul{ width:100%;}
.top_02 li{ width:100%; padding:0;}
.top_02 li:nth-child(3){ padding:0 0 40px 0;}
.top_02 img.img_01{ width:90%;}
.tab_item { font-size: 12px; line-height:12px; height: 30px;}
}
@media screen and (max-width: 330px) {
}


/* ++++++++++++++++++++ */
/* +++ top_03 +++++++++ */
/* ++++++++++++++++++++ */

.top_03{ padding:50px 0;}
.top_03 ul{ width:100%;}
.top_03 li{ width:33%; text-align:center; padding:0 0 20px 0;}
.top_03 li:nth-child(3){ }

.top_03 li img{ width:95%; display:block; margin:0 auto; }

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
.top_03 li{ width:50%; text-align:center; padding:0 0 10px 0;}
}
@media screen and (max-width: 330px) {
}


/* ++++++++++++++++++++ */
/* +++ top_04 +++++++++ */
/* ++++++++++++++++++++ */

.top_04{ padding:50px 0 100px 0;}
.top_04 ul{ width:32%; margin:0 auto;}
.top_04 li{ width:33%; text-align:center; padding:0 0 20px 0;}
.top_04 li:nth-child(3){ }

.top_04 li img{ width:95%; display:block; margin:0 auto; }


@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
.top_04 ul{ width:70%; margin:0 auto;}
.top_04 li img{ width:80%; display:block; margin:0 auto; }
}
@media screen and (max-width: 330px) {
}









